<template>
    <ButtonBack />

    <div class="section-grid section-grid--statistics">
        <div class="main-info">
            <p class="title-second">Статистика по курсу {{ course.code }}</p>
            <p>{{ course.name }}</p>
            <div class="row">
                <Period :dates="course.dates" :classes="'row__item'" />
                <div class="text-gray row__item">{{ declinationHours }}</div>
            </div>
        </div>
        <div class="button-wrapper">
            <button class="btn btn-white" @click.prevent="addDays">
                Продлить обучение на 7 дней
            </button>
        </div>
    </div>
    <template v-if="students.length">
        <div class="section-info__header">
            <div class="subtitle">Обучаемые</div>
            <button class="btn btn-icon btn-icon--green" v-if="isSaveChange">
                <div class="save"></div>
                Изменения сохранены
            </button>
        </div>
        <div class="table">
            <table>
                <thead>
                    <tr>
                        <td>№</td>
                        <td v-for="(name, key) in studentsTableNames" :key="key">{{ name }}</td>
                    </tr>
                </thead>
                <tbody>
                    <StudentInfoRow
                        v-for="(student, index) in students"
                        :key="student.id"
                        :student="student"
                        :index="index"
                    />
                </tbody>
            </table>
        </div>
        <div class="section-blog">
            <div class="section-blog__header">
                При нажатии на логин вы сможете авторизоваться в учетной записи
                ученика
            </div>
        </div>
    </template>
    <template v-if="progress.length">
        <div class="section-info__header">
            <div class="subtitle">Успеваемость</div>
        </div>
        <div class="table">
            <table>
                <thead>
                    <tr>
                        <td>№</td>
                        <td>ФИО</td>
                        <td>Билетов зачтено</td>
                        <td>Общий результат</td>
                        <td>Итоговая аттестация</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in progress" :key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.name }}</td>
                        <td>
                            {{ item.tests.passed }} из {{ item.tests.count }}
                        </td>
                        <td>{{ item.result }} верных ответов</td>
                        <td>{{ item.final }} верных ответов</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </template>
</template>

<script>
import store from "@/store/index.js";
import StudentInfoRow from "@/pages/personal/customer/performance/student-info-row";
import Period from "@/pages/personal/components/period";

export default {
    beforeRouteEnter(to, from, next) {
        const slug = to.params.slug + "?order_id=" + to.params.order_id;
        store.dispatch("CUSTOMER_GET_ITEM_COURSE", slug).then(() => {
            next();
        });
    },

    components: {
        StudentInfoRow,
        Period
    },

    computed: {
        course() {
            return this.$store.state.customer.course;
        },
        students() {
            return this.$store.state.customer.students;
        },
        studentsTableNames() {
            return this.$store.state.customer.studentsTableNames;
        },
        progress() {
            return this.$store.state.customer.progress;
        },
        isSaveChange() {
            return this.$store.state.customer.isSaveChange;
        },
        declinationHours() {
            const cases = [2, 0, 1, 1, 1, 2];
            const days = ["час", "часа", "часов"];

            const word =
                this.course.hours % 10 === 1 && this.course.hours !== 11
                    ? "академический"
                    : "академических";

            let result = "";
            if (typeof this.course.hours === "number")
                result =
                    this.course.hours +
                    " " +
                    word +
                    " " +
                    days[
                        this.course.hours % 100 > 4 &&
                        this.course.hours % 100 < 20
                            ? 2
                            : cases[
                                  this.course.hours % 10 < 5
                                      ? this.course.hours % 10
                                      : 5
                              ]
                    ];

            return result;
        }
    },

    methods: {
        addDays() {
            this.$store
                .dispatch("CUSTOMER_ADD_DAYS", {
                    order_id: this.course.order_id,
                    course_id: this.course.id
                })
                .then(response => {
                    const dates = response.data.courses.find(
                        item => item.course_id === this.course.id
                    ).dates;
                    this.$store.commit("CUSTOMER_SET_DATA_COURSE", dates);
                });
        },
        reversedDate(date) {
            return (
                date &&
                date
                    .split("-")
                    .reverse()
                    .join(".")
            );
        }
    },
    beforeUnmount() {
        this.$store.commit("CUSTOMER_SAVE_CHANGE", false);
    }
};
</script>
