<template>
    <tr>
        <td>{{ index + 1 }}</td>
        <td v-for="(item, key) in studentItems" :key="key">
            <div
                v-if="key === 'login'"
                class="link"
                @click.prevent="logInAsStudent"
            >
                {{ item }}
            </div>
            <EditCellFile
                v-else-if="key === 'certificate'"
                :value="student.certificate"
                @update:edit="sendCellValue"
            />
            <EditCell
                v-else-if="isEditableTextCell(key)"
                :type="key === 'birthday' ? 'date' : 'text'"
                :code="key"
                :value="student[key]"
                @update:edit="sendCellValue"
            />
            <template v-else-if="key === 'exam'">
                {{ item ? 'Сдан' : 'Не сдан' }}
            </template>
            <template v-else>
                {{ item ? item : '—' }}
            </template>
        </td>
    </tr>
</template>

<script>
import EditCell from "@/pages/personal/components/edit-cell"
import EditCellFile from "@/pages/personal/components/edit-cell-file";

export default {
    props: {
        student: Object,
        index: Number
    },

    components: {
        EditCell,
        EditCellFile
    },

    computed: {
        tableNamesKeys() {
            return Object.keys(this.$store.state.customer.studentsTableNames);
        },
        studentItems() {
            const result = {}
            this.tableNamesKeys.forEach(key => {
                result[key] = this.student[key]
            })
            return result
        },
    },

    methods: {
        sendCellValue(formData) {
            formData.append('id', this.student.id)

            this.$store.dispatch("CUSTOMER_UPDATE_DATA_STUDENTS", formData)
                .then(response => this.$store.commit("CUSTOMER_SET_STUDENT", response.data))
        },
        logInAsStudent() {
            this.$store.dispatch("AUTH_LOGIN_REQUEST", { password: this.student.login, role: "student" })
            .then(() =>
                this.$store.dispatch("PERSONAL_FETCH_USER_DATA").then(() => this.$router.push('/'))
            )
        },
        isEditableTextCell(key) {
            const keys = ['birthday', 'snils', 'position', 'work']
            return keys.includes(key)
        }
    }
}
</script>
